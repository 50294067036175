import { above, below } from 'src/components/global/mediaqueries';
import { BelowBreakpointValue } from 'src/components/Breakpoints';
import ArrowLink from 'src/components/Link/ArrowLink';
import getWindowSize from 'src/utils/getWindowSize';
import Heading from 'src/components/Heading';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import Video from 'src/components/Video/Video';

const Wrapper = styled('div')`
    position: relative;

    ${above.tablet_s} {
        height: calc(90vh);
    }
`;

const OuterImageWrapper = styled('div')`
    position: relative;
    height: 100%;
    width: 100%;

    ${below.tablet_s} {
        height: 0px;
        padding-bottom: 125%;
    }
`;

const ImageWrapper = styled('div')`
    height: 100%;
    width: 100%;
    overflow: hidden;

    ${below.tablet_s} {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const HeroImage = styled(Image)`
    width: 100%;
    height: 100%;
    transform: scale(1.1);

    &.standard {
        filter: blur(${theme.blur.standard.desktop});
    }
    ${below.tablet_s} {
        &.standard {
            filter: blur(${theme.blur.standard.mobile});
        }
    }
`;

const HeroVideo = styled(Video)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const TextWrapper = styled('div')`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${above.tablet_s} {
        padding-left: 33.33%;
    }
`;

const HeadingTextContainer = styled('div')`
    position: sticky;
    top: 100px;

    ${theme.spacing.mobile._32('margin-bottom')};
    ${theme.spacing.tablet._40('margin-bottom')};
    ${theme.spacing.desktop._64('margin-bottom')};

    ${above.tablet_s} {
        top: 50px;
        width: 83%;
    }
`;

const HeroHeading = styled(Heading)`
    font-style: normal;
    line-height: 115%;

    ${theme.fontSize.sans.mobile._40};
    ${theme.fontSize.sans.tablet._40};
    ${theme.fontSize.sans.desktop._80};

    ${theme.spacing.mobile._20('padding-top')};
    ${theme.spacing.tablet._24('padding-top')};
    ${theme.spacing.desktop._40('padding-top')};
    ${theme.spacing.desktop._32('margin-top')};

    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.desktop._32('padding-left')};

    ${theme.spacing.mobile._16('padding-bottom')};
    ${theme.spacing.tablet._16('padding-bottom')};
    ${theme.spacing.desktop._24('padding-bottom')};

    ${below.tablet_s} {
        width: 63%;
    }
`;

const HeroText = styled(Text)`
    line-height: 160%;
    width: 100%;

    ${above.desktop_s} {
        ${theme.fontSize.sans.desktop._20};
        line-height: 150%;
    }

    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.tablet._0('padding-right')};
    ${theme.spacing.desktop._0('padding-right')};

    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.desktop._32('padding-left')};

    ${theme.spacing.mobile._20('padding-bottom')};
    ${theme.spacing.tablet._20('padding-bottom')};
    ${theme.spacing.desktop._24('padding-bottom')};
`;

const LinkContainer = styled('div')`
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.desktop._32('padding-left')};

    ${theme.spacing.mobile._20('padding-bottom')};
    ${theme.spacing.tablet._24('padding-bottom')};
    ${theme.spacing.desktop._32('padding-bottom')};
`;

const HeroModuleImage = ({ data }) => {
    const breakpointValue = BelowBreakpointValue('tablet_s');
    const dimensions = getWindowSize();

    const { backgroundMediaFlexible, blurAmount, heading, text, link, textColor } = data;
    const heroTextColor = theme.color[textColor] || theme.color.white;
    const imageSize = blurAmount === 'none' ? 'large' : 'small';

    let desktopMedia = backgroundMediaFlexible.desktop[0].acfFcLayout;
    let mobileMedia = backgroundMediaFlexible.mobile[0].acfFcLayout;

    const desktopBackgroundVideo = backgroundMediaFlexible.desktop[0].backgroundVideo;
    const mobileBackgroundVideo = backgroundMediaFlexible.mobile[0].backgroundVideo;
    const desktopBackgroundImage = backgroundMediaFlexible.desktop[0].backgroundImage;
    const mobileBackgroundImage = backgroundMediaFlexible.mobile[0].backgroundImage;

    let desktopVideoSrc;
    let mobileVideoSrc;
    let desktopImageSrc;
    let mobileImageSrc;

    if (desktopMedia === 'video') {
        // Checks if either desktop video src or data for mobile video exists. If neither exists we change desktop media to "image" and in JSX render out mobile image src for desktop.
        if (!desktopBackgroundVideo.video && mobileBackgroundVideo === undefined) {
            desktopMedia = 'image';
        } else {
            desktopVideoSrc = desktopBackgroundVideo ? desktopBackgroundVideo.video : false;
        }
    } else {
        // Checks if either desktop image src or data for mobile image exists. If neither exists we change desktop media to "video" and in JSX render out mobile video src for desktop.
        if (!desktopBackgroundImage.image && mobileBackgroundImage === undefined) {
            desktopMedia = 'video';
        } else {
            desktopImageSrc = desktopBackgroundImage.image ? desktopBackgroundImage.image[`${imageSize}`] : false;
        }
    }

    if (mobileMedia === 'video') {
        // Checks if either mobile video src or data for desktop video exists. If neither exists we change mobile media to "image" and in JSX render out desktop image src for mobile.
        if (!mobileBackgroundVideo.video && desktopBackgroundVideo === undefined) {
            mobileMedia = 'image';
        } else {
            mobileVideoSrc = mobileBackgroundVideo ? mobileBackgroundVideo.video : false;
        }
    } else {
        // Checks if either mobile image src or data for desktop image exists. If neither exists we change mobile media to "video" and in JSX render out desktop video src for mobile.
        if (!mobileBackgroundImage.image && desktopBackgroundImage === undefined) {
            mobileMedia = 'video';
        } else {
            mobileImageSrc = mobileBackgroundImage.image ? mobileBackgroundImage.image[`${imageSize}`] : false;
        }
    }

    return (
        <Wrapper>
            <OuterImageWrapper>
                <ImageWrapper>
                    {desktopMedia === 'image' && dimensions.width >= breakpointValue && (
                        <HeroImage
                            className={blurAmount}
                            objectFit="cover"
                            fluid={desktopImageSrc ? desktopImageSrc[0] : mobileImageSrc[0]}
                        />
                    )}
                    {mobileMedia === 'image' && dimensions.width < breakpointValue && (
                        <HeroImage
                            className={blurAmount}
                            objectFit="cover"
                            fluid={mobileImageSrc ? mobileImageSrc[0] : desktopImageSrc[0]}
                        />
                    )}
                    {desktopMedia === 'video' && dimensions.width >= breakpointValue && (
                        <HeroVideo
                            playsInline
                            autoPlay
                            loop
                            src={desktopVideoSrc ? desktopVideoSrc : mobileVideoSrc}
                            type="video/mp4"
                        />
                    )}
                    {mobileMedia === 'video' && dimensions.width < breakpointValue && (
                        <HeroVideo
                            playsInline
                            autoPlay
                            loop
                            src={mobileVideoSrc ? mobileVideoSrc : desktopVideoSrc}
                            type="video/mp4"
                        />
                    )}
                </ImageWrapper>
            </OuterImageWrapper>
            <TextWrapper style={{ color: heroTextColor }}>
                <HeadingTextContainer>
                    <HeroHeading as="h1">{heading}</HeroHeading>
                    <HeroText>{text}</HeroText>
                </HeadingTextContainer>
                {link && !!link.url && !!link.text && (
                    <LinkContainer>
                        <ArrowLink color={heroTextColor} src={link.url}>
                            {link.text}
                        </ArrowLink>
                    </LinkContainer>
                )}
            </TextWrapper>
        </Wrapper>
    );
};

HeroModuleImage.propTypes = {
    data: PropTypes.shape({
        backgroundMediaFlexible: PropTypes.object,
        blurAmount: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        text: PropTypes.string,
        link: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
        }),
        textColor: PropTypes.string.isRequired,
    }),
};

HeroModuleImage.defaultProps = {
    data: {
        backgroundMediaFlexible: {},
        link: PropTypes.shape({
            text: '',
            url: '',
        }),
        text: '',
    },
};

export default HeroModuleImage;
